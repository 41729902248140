import { produce } from "immer";
import {
    GETALLEXTRACTS,
    GETTOPICCOMPLAINT,
    GETTOPICCOMPLIMENT,
    GETOCCURANCE,
    GETEXTRACT,
    CLEANUPALLEXTRACT,
    CLEANUPEXTRACT,
    CLEANUPEXTRACTOCCURANCE
} from "redux/actions/extractActions";

const initialState = {
    extracts: null,
    count: null,
    topicComplaint: null,
    topicCompliment: null,
    occurance: null,
    extract: null
};

export const extractReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETALLEXTRACTS:
                draft.extracts = action.payload.processedExtracts;
                draft.count = action.payload.totalCount;
                break;
            case GETTOPICCOMPLAINT:
                draft.topicComplaint = action.payload.processedExtracts;
                break;
            case GETTOPICCOMPLIMENT:
                draft.topicCompliment = action.payload.processedExtracts;
                break;
            case GETOCCURANCE:
                draft.occurance = action.payload;
                break;
            case GETEXTRACT:
                draft.extract = action.payload;
                break;
            case CLEANUPALLEXTRACT:
                draft.extracts = null;
                draft.count = null;
                break;
            case CLEANUPEXTRACT:
                draft.extract = null;
                break;
            case CLEANUPEXTRACTOCCURANCE:
                draft.occurance = null;
                break;
            default:
                break;
        }
    });
}