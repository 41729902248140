import { produce } from "immer";
import {
    GETHEADTOPICS,
    GETSUBTOPICS,
    TOPICINSIGHTS,
    TOPICPERFORMANCEBYID,
    TOPICINTEGRATIONDISTRIBUTION,
    CLEANUPALLTOPICS
} from "redux/actions/topicActions";

const initialState = {
    headtopics: null,
    subtopics: null,
    insights: null,
    performance: null,
    integration: null
};

export const topicsReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETHEADTOPICS:
                draft.headtopics = action.payload;
                break;
            case GETSUBTOPICS:
                draft.subtopics = action.payload;
                break;
            case TOPICINSIGHTS:
                draft.insights = action.payload;
                break;
            case TOPICPERFORMANCEBYID:
                draft.performance = action.payload;
                break;
            case TOPICINTEGRATIONDISTRIBUTION:
                draft.integration = action.payload;
                break;
            case CLEANUPALLTOPICS:
                draft.insights = null;
                break;
            case CLEANUPALLTOPICS:
                draft.performance = null;
                draft.integration = null;
                break;
            default:
                break;
        }
    });
};
