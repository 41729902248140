import axios from '../../../axios'

export const GETHEADTOPICS = 'GETHEADTOPICS'
export const GETSUBTOPICS = 'GETSUBTOPICS'
export const TOPICINSIGHTS = 'TOPICINSIGHTS'
export const TOPICPERFORMANCEBYID = 'TOPICPERFORMANCEBYID'
export const TOPICINTEGRATIONDISTRIBUTION = 'TOPICINTEGRATIONDISTRIBUTION'
export const CLEANUPALLTOPICS = 'CLEANUPALLTOPICS'
export const CLEANUPTOPIC = 'CLEANUPTOPIC'

export const getAllHeadTopics = () => {
    return (dispatch) => {
        axios.get(`/topics/headtopics`).then((res) => {
            dispatch({
                type: GETHEADTOPICS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getAllSubTopics = () => {
    return (dispatch) => {
        axios.get(`/topics/subtopics`).then((res) => {
            dispatch({
                type: GETSUBTOPICS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getTopicsInsights = (filters) => {
    return (dispatch) => {
        axios.get(`topics/insights`, { params: filters }).then((res) => {
            dispatch({
                type: TOPICINSIGHTS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getTopicPerformanceById = (type, id, filter) => {
    return (dispatch) => {
        axios.get(`/dashboard/topic-performance/${type}/${id}`, { params: filter }).then((res) => {
            dispatch({
                type: TOPICPERFORMANCEBYID,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}


export const getTopicIntegrationDistribution = (type, id, filter) => {
    return (dispatch) => {
        axios.get(`/topics/integration-distribution/${type}/${id}`, { params: filter }).then((res) => {
            dispatch({
                type: TOPICINTEGRATIONDISTRIBUTION,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const cleanUpAllTopics = () => {
    return {
        type: CLEANUPALLTOPICS
    }
}

export const cleanUpTopic = () => {
    return {
        type: CLEANUPTOPIC
    }
}