import axios from '../../../axios'
import { showSnackBar } from './snackbarActions'

export const GETALLFIXES = 'GETALLFIXES'
export const CREATENEWFIX = 'CREATENEWFIX'
export const GETEXTRACTASSOCIATEDFIX = 'GETEXTRACTASSOCIATEDFIX'
export const CLEANUPEXTRACTASSOCIATEDFIX = 'CLEANUPEXTRACTASSOCIATEDFIX'


export const getAllFixes = (head_topic_id) => {
    const url = head_topic_id ? `/fixes/get/${head_topic_id}` : `/fixes/get`
    return (dispatch) => {
        axios.get(url).then((res) => {
            dispatch({
                type: GETALLFIXES,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}


export const createNewFix = (headtopic_id, extract_id, body) => {
    return (dispatch) => {
        axios.post(`/fixes/create/headtopic/${headtopic_id}/extract/${extract_id}`, body).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(getAllFixes(headtopic_id))
            dispatch(showSnackBar("The fix has been successfully assigned to the concern.", "success"))
        }).catch((error) => {
            dispatch(showSnackBar(error.response.data.error, 'error'))
            console.log(error)
        })
    }
}


export const patchExtractToFix = (extract_id, fix_id, body) => {
    return (dispatch) => {
        axios.post(`/fixes/patch/extract/${extract_id}/fix/${fix_id}`, body).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(showSnackBar("The fix has been successfully assigned to the concern.", "success"))
        }).catch((error) => {
            dispatch(showSnackBar(error.response.data.error, 'error'))
            console.log(error)
        })
    }
}


export const getExtractAssociatedFixes = (extract_id) => {
    return (dispatch) => {
        axios.get(`/fixes/extract-fix/${extract_id}`).then((res) => {
            dispatch({
                type: GETEXTRACTASSOCIATEDFIX,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const deleteExtractAssociatedFixes = (extract_id, fix_id) => {
    return (dispatch) => {
        axios.delete(`/fixes/extract-fix/delete/${fix_id}`).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(showSnackBar(res.data.message, "success"))
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const updateExtractAssociatedFixes = (extract_id, fix_id, body) => {
    return (dispatch) => {
        axios.patch(`/fixes/extract-fix/patch/${fix_id}`, body).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(showSnackBar(res.data.message, "success"))
        }).catch((error) => {
            console.log(error)
        })
    }
}


export const cleanUpExtractAssociatedFixes = () => {
    return {
        type: CLEANUPEXTRACTASSOCIATEDFIX
    }
}