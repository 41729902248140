// assets
import {
  DashboardOutlined, LoginOutlined, ProfileOutlined, PlusOutlined, WechatWorkOutlined,
  AreaChartOutlined, ApiOutlined, NotificationOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  LoginOutlined,
  ProfileOutlined,
  PlusOutlined,
  WechatWorkOutlined,
  AreaChartOutlined,
  ApiOutlined,
  NotificationOutlined
};
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.AreaChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'topics',
      title: 'Topics',
      type: 'item',
      url: '/dashboard/topics',
      icon: icons.ProfileOutlined,
      breadcrumbs: false
    },
    {
      id: 'conversation',
      title: 'Conversations',
      type: 'item',
      url: '/dashboard/conversation',
      icon: icons.WechatWorkOutlined,
      breadcrumbs: false
    },
    {
      id: 'extracts',
      title: 'Extracts',
      type: 'item',
      url: '/dashboard/extracts',
      icon: icons.NotificationOutlined,
      breadcrumbs: false
    },
    {
      id: 'fixes',
      title: 'Fixes',
      type: 'item',
      url: '/dashboard/fixes',
      icon: icons.ApiOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
