import { useEffect, useLayoutEffect, useState } from 'react';
import useAuth from 'contexts/auth/useAuth';
import { useNavigate, Navigate } from 'react-router-dom';
import Loader from 'components/Loader';

const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth();
    const [requestedPath] = useState(window.location.pathname);

    useEffect(() => {
        // Check authentication status
        if (!isAuthenticated && !isLoading) {
            navigate('/', {
                replace: true,
                state: { from: requestedPath }
            });
        }
    }, [isAuthenticated, isLoading, navigate, requestedPath]);


    if (isLoading) {
        return <Loader />;
    }

    // Render children only when authenticated
    return isAuthenticated ? children : null;
};

export default AuthGuard;