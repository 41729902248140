import { produce } from "immer";
import { GETTENANTINTEGRATIONS } from "redux/actions/integrationActions";

const initialState = {
    tenantIntegrations: null
};

export const integrationReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETTENANTINTEGRATIONS:
                draft.tenantIntegrations = action.payload;
                break;
            default:
                break;
        }
    });
};
