import axios from "axios";

const env = "dev"
const subdomain = window.location.hostname.split('.')[0];
const apiBaseUrl = env === "production" ? `https://backend.cosmium.ai/api` : `http://localhost:5000/api`;

axios.defaults.baseURL = apiBaseUrl;
axios.defaults.withCredentials = true

axios.interceptors.request.use(
  function (config) {
    config.headers['x-tenant-subdomain'] =  subdomain
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//error and response

// axios.interceptors.response.use(
//   function (response) {
//     //any status that lie within 2xx will trigger this
//     console.log("success status request", response);
//     return response;
//   },
//   async (error) => {
//     //save original request
//     const originalRequest = error.config;

//     if (
//       error.response.status === 401 &&
//       originalRequest.url.includes("refresh-token")
//     ) {
//       //this means refresh token route is being called and refresh token is invalid
//       //clear everything and show whatever the message needed
//       //TODO redirect to logout route
//       //TODO clear all the access and refresh token
//       return Promise.reject(error);
//     } else if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       //call refresh token handler
//     //   await handleRefreshToken();

//       return axios(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

export default axios;