import { produce } from "immer";
import { GETUSERS, GETUSERDETAIL } from "redux/actions/userActions";

const initialState = {
    allUsers: null,
    userDetails: null,
};

export const userReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETUSERS:
                draft.allUsers = action.payload;
                break;
            case GETUSERDETAIL:
                draft.userDetails = action.payload;
                break;
            default:
                break;
        }
    });
};
