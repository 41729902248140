import axios from '../../../axios';
import { showSnackBar } from './snackbarActions';

export const GETUSERS = 'GETUSERS';
export const GETUSERDETAIL = 'GETUSERDETAIL';

export const getAllUsers = () => {
    return (dispatch) => {
        axios.get(`/auth/get-users`).then((res) => {
            dispatch({
                type: GETUSERS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getUserDetails = () => {
    return (dispatch) => {
        axios.get(`/auth/user-details`).then((res) => {
            dispatch({
                type: GETUSERDETAIL,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}


export const changePassword = (body) => {
    return (dispatch) => {
        axios.post(`/auth/change-password`, body).then((res) => {
            dispatch(showSnackBar(res.data.message, "success"))
        }).catch((error) => {
            console.log(error)
            dispatch(showSnackBar(error.response.data.error, "error"))
        })
    }
}