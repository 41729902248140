import { produce } from "immer";
import { SHOW, HIDE } from "redux/actions/snackbarActions";

const initialState = {
    show: false,
    severity: 'info',
    message: ''
};

export const snackBarReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SHOW:
                draft.show = true;
                draft.message = action.payload.message;
                draft.severity = action.payload.severity;
                break;
            case HIDE:
                draft.show = false;
                draft.message = '';
                draft.severity = 'info';
                break;
            default:
                break;
        }
    });
};
