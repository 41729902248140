import axios from '../../../axios'

export const GETALLEXTRACTS = 'GETALLEXTRACTS'
export const GETTOPICCOMPLIMENT = 'GETTOPICCOMPLIMENT'
export const GETTOPICCOMPLAINT = 'GETTOPICCOMPLAINT'
export const GETOCCURANCE = 'GETOCCURANCE'
export const GETEXTRACT = 'GETEXTRACT'
export const CLEANUPALLEXTRACT = 'CLEANUPALLEXTRACT'
export const CLEANUPEXTRACT = 'CLEANUPEXTRACT'
export const CLEANUPEXTRACTOCCURANCE = 'CLEANUPEXTRACTOCCURANCE'



export const getAllExtracts = (filters, type) => {
    return (dispatch) => {
        axios.get(`/extracts`, {
            params: {
                ...filters,
                headtopics: filters.headtopics.length > 0 ? filters.headtopics.join(',') : undefined,
                subtopics: filters.subtopics.length > 0 ? filters.subtopics.join(',') : undefined,
                types: filters.types.length > 0 ? filters.types.join(',') : undefined,
            }
        }).then((res) => {
            dispatch({
                type,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getExtract = (id) => {
    return (dispatch) => {
        axios.get(`/extracts/${id}`).then((res) => {
            dispatch({
                type: GETEXTRACT,
                payload: res.data
            })
        })
    }
}

export const getExtractOccurance = (id, filter) => {
    return (dispatch) => {
        axios.get(`/extracts/extract-frequency/${id}`, { params: filter }).then((res) => {
            dispatch({
                type: GETOCCURANCE,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const cleanUpAllExtracts = () => {
    return {
        type: CLEANUPALLEXTRACT
    }
}

export const cleanUpExtract = () => {
    return {
        type: CLEANUPEXTRACT
    }
}

export const cleanUpExtractOccurance = () => {
    return {
        type: CLEANUPEXTRACTOCCURANCE
    }
}