import { produce } from "immer";
import { GETALLFIXES, GETEXTRACTASSOCIATEDFIX, CLEANUPEXTRACTASSOCIATEDFIX } from "redux/actions/fixActions";

const initialState = {
    allFixes: null,
    count: null,
    associatedFix: null
};

export const fixReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETALLFIXES:
                draft.allFixes = action.payload.fixes;
                draft.count = action.payload.count;
                break;
            case GETEXTRACTASSOCIATEDFIX:
                draft.associatedFix = action.payload;
                break;
            case CLEANUPEXTRACTASSOCIATEDFIX:
                draft.associatedFix = null;
                break;
            default:
                break;
        }
    });
}
