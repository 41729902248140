import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import Dashboard from 'layout/Dashboard';
import AuthGuard from 'components/authGuard/guard';

const Conversations = Loadable(lazy(() => import('pages/conversations/index')));
const Extracts = Loadable(lazy(() => import('pages/extracts')));
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/index')));
const ProfileTabs = Loadable(lazy(() => import('pages/profile/index')));
const ManageUser = Loadable(lazy(() => import('pages/manageUsers/manageUser')));
const CreateUser = Loadable(lazy(() => import('pages/manageUsers/createUser')));
const Topics = Loadable(lazy(() => import('pages/topics/allTopics')));
const Transcript = Loadable(lazy(() => import('pages/transcript/transcript')));
const ExtractDetail = Loadable(lazy(() => import('pages/extractInfo/index')));
const TopicDetails = Loadable(lazy(() => import('pages/topicDetail/topicDetail')));


// render - sample page
const Fixes = Loadable(lazy(() => import('pages/fixes/fixes')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/dashboard',
  element:
    <AuthGuard>
      <Dashboard />,
    </AuthGuard>
  ,
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/dashboard/conversation',
      element: <Conversations />
    },
    {
      path: '/dashboard/fixes',
      element: <Fixes />
    },
    {
      path: '/dashboard/extracts', 
      element: <Extracts />
    },
    {
      path: '/dashboard/user/profile/*',
      element: <ProfileTabs />
    },
    {
      path: '/dashboard/user/manage',
      element: <ManageUser />
    },
    {
      path: '/dashboard/user/create',
      element: <CreateUser />
    },
    {
      path: '/dashboard/topics',
      element: <Topics />
    },
    {
      path: '/dashboard/topics/:type/:id',
      element: <TopicDetails />
    },
    {
      path: '/dashboard/conversation/:id',
      element: <Transcript />
    },
    {
      path: '/dashboard/extracts/:id',
      element: <ExtractDetail />
    }
  ]
};

export default MainRoutes;
