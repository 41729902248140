import axios from '../../../axios';

export const TOPCOMPLAINTS = 'TOPCOMPLAINTS';
export const TOPCOMPLIMENTS = 'TOPCOMPLIMENTS';
export const TOPICPERFORMANCE = 'TOPICPERFORMANCE';
export const OVERALLSENTIMENT = 'OVERALLSENTIMENT';
export const CLEANUPFUNCTIONDASHBOARD = 'CLEANUPFUNCTIONDASHBOARD';
export const TOPDELIGHTANDPAIN = 'TOPDELIGHTANDPAIN';


export const getTopComplaints = (filters) => {
    return (dispatch) => {
        axios.get(`/dashboard/complaints/top`, { params: filters }).then((res) => {
            dispatch({
                type: TOPCOMPLAINTS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getTopCompliments = (filters) => {
    return (dispatch) => {
        axios.get(`/dashboard/compliments/top`, { params: filters }).then((res) => {
            dispatch({
                type: TOPCOMPLIMENTS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getTopicPerfoemance = (filters) => {
    return (dispatch) => {
        axios.get(`/dashboard/topic-performance/headtopic`, { params: filters }).then((res) => {
            dispatch({
                type: TOPICPERFORMANCE,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}


export const getTopPaintAndDelight = (filters) => {
    return (dispatch) => {
        axios.get(`/dashboard/top-pain-delight`, { params: filters }).then((res) => {
            dispatch({
                type: TOPDELIGHTANDPAIN,
                payload: res.data
            })
        })
    }
}



export const getOverallSentiment = (filters) => {
    return (dispatch) => {
        axios.get(`/dashboard/overall-sentiment`, { params: filters }).then((res) => {
            dispatch({
                type: OVERALLSENTIMENT,
                payload: res.data
            })
        })
    }
}

export const cleanUpAllDashboardStates = () => {
    return {
        type: CLEANUPFUNCTIONDASHBOARD
    }
}