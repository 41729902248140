import axios from '../../../axios'

export const GETTENANTINTEGRATIONS = 'GETTENANTINTEGRATIONS';

export const getTenantIntegrations = () => {
    return (dispatch) => {
        axios.get(`/integration/tenant-integrations`).then((res) => {
            dispatch({
                type: GETTENANTINTEGRATIONS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}