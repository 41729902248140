import { produce } from "immer";
import {
    CLEANUPFUNCTIONDASHBOARD,
    OVERALLSENTIMENT,
    TOPCOMPLAINTS,
    TOPCOMPLIMENTS,
    TOPICPERFORMANCE,
    TOPDELIGHTANDPAIN
} from "redux/actions/dashboardActions";

const initialState = {
    topComplaints: null,
    topCompliments: null,
    topicPerformance: null,
    overallSentiment: null,
    painAndDelight: null
};

export const dashboardReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case TOPCOMPLAINTS:
                draft.topComplaints = action.payload;
                break;
            case TOPCOMPLIMENTS:
                draft.topCompliments = action.payload;
                break;
            case TOPICPERFORMANCE:
                draft.topicPerformance = action.payload;
                break;
            case OVERALLSENTIMENT:
                draft.overallSentiment = action.payload;
                break;
            case TOPDELIGHTANDPAIN:
                draft.painAndDelight = action.payload;
                break;
            case CLEANUPFUNCTIONDASHBOARD:
                Object.assign(draft, initialState);
                break;
            default:
                break;
        }
    });
}