import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/logo';
import icon from '../../../../../New cosmium_logo/Without bg/Artboard 7.png'

// ==============================|| DRAWER HEADER ||============================== //

export default function DrawerHeader({ open }) {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={!!open}>
      {open ?
        <Logo sx={{ width: open ? 'auto' : 35, height: 35}} />
        :
        <img
          src={icon}
          width={40}
          height={40}
        />
      }
    </DrawerHeaderStyled>
  );
}

DrawerHeader.propTypes = { open: PropTypes.bool };
