import axios from '../../../axios'

export const GETCONVERSATIONS = 'GETCONVERSATIONS'
export const GETONETRANSCRIPT = 'GETONETRANSCRIPT'
export const CLEANALLCONVERSATIONS = 'CLEANALLCONVERSATIONS'
export const CLEANUPCONVERSATION = 'CLEANUPCONVERSATION'


export const getConversations = (filters) => {
    return (dispatch) => {
        axios.get(`/transcripts`, {
            params: {
                ...filters,
                headtopics: filters.headtopics.length > 0 ? filters.headtopics.join(',') : undefined,
                subtopics: filters.subtopics.length > 0 ? filters.subtopics.join(',') : undefined,
                channels: filters.channels.length > 0 ? filters.channels.join(',') : undefined,
                sources: filters.sources.length > 0 ? filters.sources.join(',') : undefined,
                extracts: filters.extracts.length > 0 ? filters.extracts.join(',') : undefined,
            }
        }).then((res) => {
            dispatch({
                type: GETCONVERSATIONS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const cleanUpAllConversation = () => {
    return {
        type: CLEANALLCONVERSATIONS
    }
}

export const getTranscript = (id) => {
    return (dispatch) => {
        axios.get(`/transcripts/${id}`).then((res) => {
            dispatch({
                type: GETONETRANSCRIPT,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const cleanUpConversation = () => {
    return {
        type: CLEANUPCONVERSATION
    }
}
